<template>
  <div id="schedule">
    <!-- 日程安排 -->
    <!-- <div class="banner spc">
      <h1>大会日程</h1>
      <p>年度技术创新及研发实践的架构案例</p>
    </div> -->
    <Title :title="title" :description="description"></Title>
    <div class="schedule_con">
      <!-- spc -->
      <div id="menu" class="spc" style="display: none">
        <ul>
          <li v-for="(item,index) in days" :key="'menu_'+item">
            <a @click.prevent="clickLi_(index)" :class="pday == index ? 'dianji current' : 'dianji'">DAY{{index+1}}</a>
          </li>
        </ul>
      </div>
      <div class="richeng spc">
        <ul class="daohang">
          <li v-for="(item, index) in days" :class="pday == index ? 'active col-lg-4 col-md-4 col-sm-4' : 'col-lg-4 col-md-4 col-sm-4'" :key="'r_daohang_' + index">
            <a class="dianji" @click="clickLi_(index)">
              <div>
                <h1>DAY{{ index + 1 }}</h1>
                <span>{{ item }}</span>
              </div>
            </a>
          </li>
        </ul>
        <ul class="content content_spc content_ content_spc_">
          <li v-for="(item, index) in days_list" :key="'day_'+index" class="show" :id="'item'+(index+1)">
            <h4>DAY{{index+1}}</h4>
            <table>
              <tbody>
                <!-- am -->
                <!-- am project -->
                <tr class="top">
                  <td>
                    <img src="../assets/img/time.png"/>
                  </td>
                  <td class="" v-for="(plitem,plindex) in project_list[index*2]" :key="'day_'+index+'_amtitle_'+plindex">
                    {{plitem.venueName}}<br>
                    <!-- <span class="chup" style="font-size: 14px;cursor: pointer;" v-if="plitem.producers" @click="plitem.producerId?toNext('teacher',plitem.producerId):''">出品人：{{plitem.producers}}</span> -->
                    <span class="chup" style="font-size: 14px;cursor: pointer;" v-if="plitem.producers" @click="plitem.producerId?toNext('subject',plitem.id):''">出品人：{{plitem.producers}}</span>
                    <span class="chup" style="font-size: 14px;cursor: pointer;" v-if="!plitem.producers">出品人：大咖正在就位</span>
                  </td>
                </tr>
                <!-- am schedule -->
                <tr class="kecheng" v-for="(titem,tindex) in times[0]" :key="'day_'+index+'_amtr_'+tindex">
                  <td>
                    {{schedule.timesData[titem][0]}}<br>-<br>{{schedule.timesData[titem][1]}}
                  </td>
                  <!-- 2021giac Day2上午有更改 -->
                  <slot v-for="(iitem) in num">
                    <!-- Day1 场1||2||5||6   Day2 场1||2||3||6   ——>   Day2 场1||2||3||6 -->
                    <!-- <slot v-if="(!(index==0&&(iitem==3||iitem==4)))&&(!(index==1&&(iitem==4||iitem==5)))"> -->
                    <slot v-if="!(index==1&&(iitem==4||iitem==5))">
                      <!-- <slot v-for="(v,k,) in schedule.datas[days_list[index]]['am'][titem]">
                        <td @click="toNext('course',v.courseId)" v-if="k==iitem" :key="'day_'+index+'_amtr_'+tindex+'_td_'+iitem">
                          <div class="biaoti">
                            {{v.courseTitle}}
                          </div>
                          <div class="ren">
                            <h2>{{v.lecturers[0].name}}</h2>
                            <p>{{v.lecturers[0].company}}<br>{{v.lecturers[0].position}}</p>
                          </div>
                        </td>
                      </slot> -->
                      <td v-if="schedule.datas[days_list[index]]['am'][titem][iitem]"
                          :key="'day_'+index+'_amtr_'+tindex+'_td_'+iitem"
                          @click="toNext('course',schedule.datas[days_list[index]]['am'][titem][iitem].courseId)"
                          style="height: 280px;">
                        <div class="biaoti">
                          {{schedule.datas[days_list[index]]['am'][titem][iitem].courseTitle}}
                        </div>
                        <div class="ren">
                          <h2>{{schedule.datas[days_list[index]]['am'][titem][iitem].lecturers[0].name}}</h2>
                          <p>
                            {{schedule.datas[days_list[index]]['am'][titem][iitem].lecturers[0].company}}<br>
                            {{schedule.datas[days_list[index]]['am'][titem][iitem].lecturers[0].position}}
                          </p>
                        </div>
                      </td>
                      <td v-else :key="'day_'+index+'_amtr_'+tindex+'_td_'+iitem" style="height: 280px;">
                        敬请期待
                      </td>
                    </slot>
                    <!-- Day1 场3||4  Day2 场4||5  ——>  Day2 场4||5 -->
                    <td rowspan="3" :key="'day_'+index+'_amtr_'+tindex+'_td_'+iitem"
                        v-else-if="(index==1&&tindex==0&&(iitem==4||iitem==5))" >
                        <!-- v-else-if="(index==0&&tindex==0&&(iitem==3||iitem==4))||(index==1&&tindex==0&&(iitem==4||iitem==5))" > -->
                      <table width="100%">
                        <tbody>
                          <tr class="kecheng" style="border:none;" 
                              v-for="(tnitem,tnindex) in times_new" :key="'day_'+index+'_amtr_'+tnindex">
                            <!-- <slot v-for="(v,k,i) in schedule.datas[days_list[index]]['am'][tnitem]">
                              <td style="border-right:none;"
                                  v-if="k==iitem"
                                  :key="'day_'+index+'_amtr_'+tnindex+'_td_'+iitem+'_'+i"
                                  @click="toNext('course',v.courseId)">
                                <div class="biaoti">
                                  {{v.courseTitle}}
                                </div>
                                <div class="ren">
                                  <h2>{{v.lecturers[0].name}}</h2>
                                  <p>{{v.lecturers[0].company}}<br>{{v.lecturers[0].position}}</p>
                                </div>
                              </td>
                            </slot> -->
                            <td :style="(tnindex==0?'border-top:none;':'')+'border-left:none;border-right:none;height:210px;'"
                                v-if="schedule.datas[days_list[index]]['am'][tnitem][iitem]"
                                @click="toNext('course',schedule.datas[days_list[index]]['am'][tnitem][iitem].courseId)">
                              <div class="biaoti">
                                {{schedule.datas[days_list[index]]['am'][tnitem][iitem].courseTitle}}
                              </div>
                              <div class="ren">
                                <h2>{{schedule.datas[days_list[index]]['am'][tnitem][iitem].lecturers[0].name}}</h2>
                                <p>
                                  {{schedule.datas[days_list[index]]['am'][tnitem][iitem].lecturers[0].company}}<br>
                                  {{schedule.datas[days_list[index]]['am'][tnitem][iitem].lecturers[0].position}}
                                </p>
                              </div>
                            </td>
                            <td v-else :style="(tnindex==0?'border-top:none;':'')+'border-left:none;border-right:none;height:210px;'">
                              敬请期待
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </slot>
                </tr>
                <!-- pm -->
                <!-- pm project -->
                <tr class="top">
                  <td>
                    <img src="../assets/img/time.png"/>
                  </td>
                  <td class="" v-for="(plitem,plindex) in project_list[index*2+1]" :key="'day_'+index+'_pmtitle_'+plindex">
                    {{plitem.venueName}}<br>
                    <!-- <span class="chup" style="font-size: 14px;cursor: pointer;" v-if="plitem.producers" @click="plitem.producerId?toNext('teacher',plitem.producerId):''">出品人：{{plitem.producers}}</span> -->
                    <span class="chup" style="font-size: 14px;cursor: pointer;" v-if="plitem.producers" @click="plitem.producerId?toNext('subject',plitem.id):''">出品人：{{plitem.producers}}</span>
                    <span class="chup" style="font-size: 14px;cursor: pointer;" v-if="!plitem.producers">出品人：大咖正在就位</span>
                  </td>
                </tr>
                <!-- pm schedule -->
                <tr class="kecheng" v-for="(titem,tindex) in times[1]" :key="'day_'+index+'_pmtr_'+tindex">
                  <td>
                    {{schedule.timesData[titem][0]}}<br>-<br>{{schedule.timesData[titem][1]}}
                  </td>
                  <!-- <td v-for="(iitem) in num" :key="'day_'+index+'_pmtr_'+tindex+'_td_'+iitem">
                    <slot v-for="(v,k,i) in schedule.datas[days_list[index]]['pm'][titem]">
                      <div class="biaoti" v-if="k==iitem" :key="'day_'+index+'_amtr_'+tindex+'_td_'+iitem+'_key'+i">
                        {{v.courseTitle}}
                      </div>
                      <div class="ren" v-if="k==iitem" :key="'day_'+index+'_pmtr_'+tindex+'_td_'+iitem+'_text'+i">
                        <h2>{{v.lecturers[0].name}}</h2>
                        <p>{{v.lecturers[0].company}}<br>{{v.lecturers[0].position}}</p>
                      </div>
                    </slot>
                    <div v-if="!schedule.datas[days_list[index]]['pm'][titem][iitem]">敬请期待</div>
                  </td> -->
                  <slot v-for="(iitem) in num">
                    <td v-if="schedule.datas[days_list[index]]['pm'][titem][iitem]"
                        :key="'day_'+index+'_pmtr_'+tindex+'_td_'+iitem" style="height: 280px;"
                        @click="toNext('course',schedule.datas[days_list[index]]['pm'][titem][iitem].courseId)">
                      <div class="biaoti" :key="'day_'+index+'_amtr_'+tindex+'_td_'+iitem+'_'+iitem">
                        {{schedule.datas[days_list[index]]['pm'][titem][iitem].courseTitle}}
                      </div>
                      <div class="ren" :key="'day_'+index+'_pmtr_'+tindex+'_td_'+iitem+'_text'+iitem">
                        <h2>{{schedule.datas[days_list[index]]['pm'][titem][iitem].lecturers[0].name}}</h2>
                        <p>
                          {{schedule.datas[days_list[index]]['pm'][titem][iitem].lecturers[0].company}}<br>
                          {{schedule.datas[days_list[index]]['pm'][titem][iitem].lecturers[0].position}}
                        </p>
                      </div>
                    </td>
                    <!-- <div v-if="schedule.datas[days_list[index]]['pm'][titem][iitem]">123</div> -->
                    <td v-if="!schedule.datas[days_list[index]]['pm'][titem][iitem]" :key="'day_'+index+'_pmtr_'+tindex+'_td_'+iitem">
                      敬请期待
                    </td>
                  </slot>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
      </div>
      <div class="clearboth spc"></div>
      <!-- smob -->
      <div class="richeng smob">
        <ul class="daohang smob">
          <li v-for="(item, index) in days" :key="'r_daohang_' + index"
              :class="mday == index ? 'active col-lg-4 col-md-4 col-sm-4' : 'col-lg-4 col-md-4 col-sm-4'">
            <a class="dianji" @click.prevent="showDay(index)">
              <h1>DAY{{ index + 1 }}</h1>
            </a>
          </li>
        </ul>
        <div class="content content_smob">
          <ul class="day_menu">
            <li v-for="(it1,i1) in project_smob[mday]" :key="'smob_courses_'+i1" 
                :style="courses_index==i1?'background: #e0e0e0;':'background: #f9f9f9;'" @click="showCourses(i1)">
              {{it1['title']}}
            </li>
          </ul>
          <div class="clearboth smob"></div>
          <ul class="day_list" v-if="project_smob[mday] && project_smob[mday][courses_index] && project_smob[mday][courses_index]['list']">
            <li v-for="(it2,i2) in project_smob[mday][courses_index]['list']" :key="'smob_course_'+i2" :class="'day_list_li'+i2">
              <!-- <a @click.prevent="toNext('course',it2.courseId);"> -->
              <router-link :to="{path:'/2022sh/course',name:'TwoSHCourse',query:{id:it2.courseId}}">
                <h1>{{it2.courseTitle}}</h1>
                <p>
                  <span>{{it2.lecturers[0].name}}</span> {{it2.lecturers[0].company}} {{it2.lecturers[0].position}}
                </p>
                <p>
                  <span class="glyphicon glyphicon-time"></span>
                  {{project_smob[mday][courses_index].date}} {{it2.time}}
                </p>
              </router-link>
              <!-- </a> -->
            </li>
          </ul>
        </div>
      </div>
      <div class="clearboth smob"></div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/Title';
import $ from "jquery";
import {sid,getSpeechInfo,getScheduleInfo} from "@/utils/api.js"
export default {
  name: "Schedule",
  data() {
    return {
      days: [],
      days_list: [],
      times: [],
      schedule: [],
      num: 6,
      project_list:[],
      pday: 0,
      mday: 0,
      courses_index: 0,
      project: [],
      project_smob: [],
      title: '大会日程',
      description: '年度技术创新及研发实践的架构案例',
      course_list: [[],[]],
    };
  },
  components: { Title },
  created() {
    this.getSpeech();
    this.getSchedule();
  },
  mounted() {
    this.showMenu();
  },
  methods: {
    scrollSmoothTo: function (position) {
      if (!window.requestAnimationFrame) {
          window.requestAnimationFrame = function(callback) {
              return setTimeout(callback, 17);
          };
      }
      // 当前滚动高度
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // 滚动step方法
      var step = function () {
          // 距离目标滚动距离
          var distance = position - scrollTop;
          // 目标滚动位置
          // console.log('scrollSmoothTo ', position, scrollTop, distance, document.body.offsetTop);
          scrollTop = scrollTop + distance / 5;
          if (Math.abs(distance) < 1) {
              window.scrollTo(0, position);
          } else {
              window.scrollTo(0, scrollTop);
              requestAnimationFrame(step);
          }
      };
      step();
    },
    clickLi_: function (i) {
      this.pday = i;
      if(i==0){
        this.scrollSmoothTo(document.getElementById('item1').offsetTop); //.getElementsByTagName('table')[0]
      } else if(i==1){
        this.scrollSmoothTo(document.getElementById('item2').offsetTop);
      }
    },
    showMenu: function () {
      let _this = this;
      let w_width = window.innerWidth;
      window.onresize = ()=>{
        w_width = window.innerWidth;
      };
      $("#menu").css({ display: "none" });
      $(function () {
        $(window).scroll(function () {
          if(_this.$route.path == ("/schedule")){ // 只对当前页面生效
            if(w_width > 750){
              if ($(window).scrollTop() > 1600) {
                $("#menu").fadeIn(500);
                _this.pday = 1;
              } else {
                $("#menu").fadeOut(500);
                _this.pday = 0;
              }
            }
          }
        });
      });
    },
    showDay: function(i){
      this.mday = i;
      this.courses_index = 0;
    },
    showCourses: function(i){
      this.courses_index = parseInt(i);
    },
    formatTime(timestamp, tag) {
      const time = new Date(timestamp * 1000);
      const year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      let week = time.getDay();
      switch (week) {
        case 0:
          week = "星期日";
          break;
        case 1:
          week = "星期一";
          break;
        case 2:
          week = "星期二";
          break;
        case 3:
          week = "星期三";
          break;
        case 4:
          week = "星期四";
          break;
        case 5:
          week = "星期五";
          break;
        case 6:
          week = "星期六";
          break;
        default:
          break;
      }
      let res = "";
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      if (tag === "start") {
        res = `${year}.${month}.${day}   ${week}`;
      } else if (tag === "day") {
        res = `${year}.${month}.${day}`;
      } else {
        res = `${month}.${day}`;
      }
      return res;
    },
    getSpeech: function(){
      var project = [];
      this.$http
        .jsonp(getSpeechInfo + sid(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(data => {
          console.log(data);
          if(data.errno == 0){
            // console.log("getSpeech data: ",data.data);
            project = data.data;
            this.project = project;
          }
        })
    },
    getSchedule: function () {
      this.$http
        .jsonp(getScheduleInfo + sid(this.global.year))
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          console.log(data);
          if (data.errno == 0) {
            // console.log("schedule ",data.data);
            // console.log("project: ",this.project);
            for(let i=0;i<4;i++){
              this.project_list.push([]);
              for(let j=0;j<6;j++){
                this.project_list[i].push(this.project[4*j+i]);
              }
            }
            // console.log("project_list: ",this.project_list);
            this.schedule = data.data; // 20210513
            this.times = [["9:00", "10:00", "11:00"], ["13:30", "14:30", "15:50", "16:50"]]; // 20210513
            this.times_new = ["8:45", "9:35", "10:25", "11:15"]; // 20210513
            // console.log("scheduleDatas ",data.data.datas[1624550400]['am']['8:45'][1]);

            // smob
            // 专题
            let fkeys = Object.keys(data.data.finishing);
            for(let f=0;f<fkeys.length;f++){
              this.project_smob.push([]);

              // 手机端数据（模板）
              for(let d=0;d<this.num;d++){
                // console.log("403 ",d,data.data.finishing[fkeys[f]]);
                let amData = data.data.finishing[fkeys[f]][(d+1)+'am'];
                let pmData = data.data.finishing[fkeys[f]][(d+1)+'pm'];
                // console.log("amData ",amData,this.formatTime(amData.date,'day'));
                // console.log("pmData ",pmData,this.formatTime(pmData.date,'day'));
                this.project_smob[f].push({title:'', date:this.formatTime(pmData.date,'day'), list:[]});
                this.project_smob[f][d].title = amData.groupName;
                // this.project_smob[f][d].title = amData.name+'/'+pmData.name;
                // console.log(this.project_smob[f]);
              }
              // console.log("project_smob ",this.project_smob);
            }
            // 课程（手机端数据添加）
            let d_keys = Object.keys(data.data.datas);
            for(let d=0;d<d_keys.length;d++){
              let ap_keys = Object.keys(data.data.datas[d_keys[d]]);
              for(let a=0;a<ap_keys.length;a++){
                let t_keys = Object.keys(data.data.datas[d_keys[d]][ap_keys[a]]);
                for(let t=0;t<t_keys.length;t++){
                  let courses = data.data.datas[d_keys[d]][ap_keys[a]][t_keys[t]];
                  // console.log("courses ",courses);
                  let c_keys = Object.keys(courses);
                  let cl = c_keys.length;
                  for(let c=0;c<cl-1;c++){
                    let course = courses[c_keys[c]];
                    // console.log(c_keys[c],"course ",course);
                    let time0 = courses[c_keys[(cl-1)]][0];
                    this.project_smob[d][c_keys[c]-1].list.push(course);
                    let list_index = this.project_smob[d][c_keys[c]-1].list.length - 1;
                    this.project_smob[d][c_keys[c]-1].list[list_index].time = time0;
                    // console.log("308  ",courses[c_keys[(cl-1)]],this.project_smob[d][c_keys[c]-1].list);
                  }
                }
              }
            }
            // console.log("this.project_smob ",this.project_smob);


            // spc端添加数据
            // 日期
            var datas_keys = Object.keys(data.data.datas);
            for (let i = 0; i < datas_keys.length; i++) {
              this.days_list.push(datas_keys[i]); // 20210513
              this.days.push(this.formatTime(datas_keys[i], "start"));
            }
          }
        });
    },
    toNext: function(p,id){
      switch(p){
        case 'subject':
          if(id){
            this.$router.push({path:"/2022sh/subject",name:"TwoSHSubject",query:{id:id}});
          }
          break;
        case 'course':
          if(id){
            this.$router.push({path:"/2022sh/course",name:"TwoSHCourse",query:{id:id}});
          }
          break;
        case 'teacher':
          if(id){
            this.$router.push({path:"/2022sh/teacher",name:"TwoSHTeacher",query:{id:id}});
          }
          break;
        default:
          break;
      }
    }
  },
};
</script>

<style lang="scss">
a,a:hover,a:visited {
  color: #000000;
  text-decoration: none;
}
// div
h1,h2,h3,h4,h5,h6,p,a,span,b,i,em {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}

#schedule {
  // margin-top: 125px;
  text-align: left;
  .banner {
    // margin-top: 135px;
    width: 100%;
    height: 300px;
    background-color: #fafbfc;
    h1 {
      letter-spacing: 8px;
      line-height: 50px;
      padding-top: 125px;
      text-align: center;
      font-family: PingFangSCBold;
      font-size: 50px;
      font-weight: bold;
      color: #2c3e50;
    }
    p {
      line-height: 24px;
      padding-top: 6px;
      text-align: center;
      font-family: PingFangSCBold;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 4px;
      color: #666666;
    }
  }
  .schedule_con {
    width: 100%;
    // height: 500px;
    #menu {
      position: fixed;
      bottom: 50px;
      right: 50px;
      margin-left: 400px;
      width: 50px;
      ul {
        li {
          a {
            display: block;
            border: 1px solid #0088bb;
            margin: 5px 0;
            font-size: 14px;
            font-weight: bold;
            color: #333;
            width: 80px;
            height: 50px;
            line-height: 50px;
            text-decoration: none;
            text-align: center;
          }
          a:hover,
          a.current {
            border: 1px solid #003177;
            color: #fff;
            background: #003177;
          }
        }
      }
    }
    .richeng {
      max-width: 1440px;
      margin: 0 auto;
      margin-top: 45px;
      .daohang {
        width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        li {
          text-align: center;
          float: left;
          height: 100px;
          a {
            color: #000000;
          }
          div {
            margin: 0 auto;
            width: 80%;
            text-align: center;
            height: 100px;
            border: 1px solid #0b70a4;
            cursor: pointer;
            transition: all 0.3s;
          }
          div:hover {
            background: #003177;
            opacity: 0.8;
            color: #ffffff;
          }
          h1 {
            margin: 0;
            font-weight: 500;
            padding-top: 25px;
            font-size: 36px;
            text-align: center;
          }
          span {
            font-size: 14px;
            white-space: pre;
          }
        }
        .active {
          div {
            color: #ffffff;
            background: #003177;
            span {
              font-weight: 500;
            }
          }
        }
      }
      .content,.content_ {
        max-width: 1300px;
        margin: 0 auto;
        li {
          h4 {
            text-align: center;
            margin-top: 20px;
            margin-bottom: 20px;
            font-weight: 500;
            font-size: 40px;
            padding-top: 45px;
            padding-bottom: 35px;
          }
          table {
            border-collapse: collapse;
            border-spacing: 0;
            .top {
              td {
                border: 1px solid #dddddd;
                border-right: none;
                border-bottom: none;
                height: 95px;
                width: 10%;
                font-size: 18px;
                color: #000000;
                font-weight: bold;
                padding-left: 14px;
                .chup {
                  font-size: 14px;
                }
              }
              td:first-child {
                width: 7%;
                height: 95px;
                padding-left: 0px;
              }
              td:last-child {
                border-right: 1px solid #dddddd;
              }
            }
            .kecheng {
              h2 {
                font-size: 14px;
                color: #333333;
                font-weight: bold;
                margin-top: 10px;
              }
              p {
                font-size: 14px;
                color: #666666;
                padding-top: 5px;
                padding-left: 5px;
              }
              td {
                border: 1px solid #dddddd;
                border-right: none;
                border-bottom: none;
                width: 10%;
                background: #fafbfc;
                text-align: center;
                font-size: 14px;
                color: #333;
                cursor: pointer;
                .biaoti {
                  color: #1f63af;
                  font-size: 16px;
                  line-height: 22px;
                  padding: 0 10px;
                  width: 100%;
                  overflow: hidden;
                  margin-bottom: 5px;
                }
                .ren {
                  width: 100%;
                  padding: 2px;
                  h2 {
                    font-size: 14px;
                    color: #333333;
                    font-weight: bold;
                    margin-top: 10px;
                    margin-bottom: 0;
                  }
                  p {
                    font-size: 14px;
                    color: #666666;
                    padding-top: 5px;
                    padding-left: 5px;
                  }
                }
              }
              td:first-child {
                width: 105px;
                height: 180px;
                font-size: 16px;
                color: #000000;
                text-align: center;
                line-height: 24px;
                padding-left: 0;
              }
              td:last-child {
                border-right: 1px solid #dddddd;
              }
              .course-href {
                cursor: pointer;
              }
            }
            tr:last-child {
              border-bottom: 1px solid #dddddd;
            }
          }
        }
      }
      .daohang.smob{
        li{
          width: 33%;
          min-width: 135px;
          height: auto;
          // border: 1px solid #0b70a4;
          border: 1px solid #003177;
          h1{
            padding: 0;
            font-size: 30px;
            line-height: 60px;
          }
        }
        .active{
          // background: #0b70a4;
          background: #003177;
          h1{
            color: #ffffff;
          }
        }
      }
      .content_smob{
        ul.day_menu{
          margin-top: 30px;
          li{
            float: left;
            width: 46%;
            height: 60px;
            line-height: 60px;
            background: #f9f9f9;
            margin-bottom: 8px;
            padding-left: 10px;
            padding-right: 10px;
            font-size: 15px;display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          li:nth-of-type(2n+1){
            margin-left: 3%;
            margin-right: 1%;
          }
          li:nth-of-type(2n){
            margin-left: 1%;
          }
        }
        ul.day_list{
          margin-top: 10px;
          // display: none;
          li{
            width: 94%;
            margin: 0 auto;
            background: #f9f9f9;
            padding: 20px;
            margin-bottom: 10px;
            a{
              color: #000000;
              text-decoration: none;
              h1{
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 10px;
              }
              p:nth-of-type(1){
                margin-bottom: 5px;
                span{
                  font-weight: 500;
                  margin-right: 5px;
                }
              }
              p:nth-of-type(2){
                span{
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.clearboth {
  clear: both;
  float: none !important;
  width: 0 !important;
  height: 0 !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.spc{
  display: block;
}
.smob{
  display: none;
}

@media (max-width: 900px) {
  .spc{
    display: none;
  }
  .smob{
    display: block;
  } 
}
@media (max-width: 1023px) {
  #schedule{
    margin-top: 0;
  }
}
</style>